const target = 'reserveRecord'
export default {
  doCount: {
    config: {
      url: `/${target}/doCount`,
      method: 'post',
    },
  },
  listData: {
    config: {
      url: `/${target}/listData`,
      method: 'post',
    },
  },
  adminSave: {
    config: {
      url: `/${target}/adminSave`,
      method: 'post',
    },
  }, 
  adminDel: {
    config: {
      url: `/${target}/adminDel`,
      method: 'post',
    },
  },
};

