import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import http from "@/http";

const SPACE_NAME = "app";

export interface MenuItem {
  name: string;
  children?: MenuItem[];
  path?: string;
}

interface InitialState {
  userInfo: {
    [key: string]: any;
  };
  menus: MenuItem[];
  permission: {
    [key: string]: boolean;
  };
  area: any[];
}

const initialState: InitialState = {
  userInfo: {},
  menus: [],
  permission: {},
  area: []
};
export const fetchArea: any = createAsyncThunk(
  `${SPACE_NAME}/fetchArea`,
  async countryId => {
    const data = await http('platform', 'getTree', { params: { countryId } });
    return formatArea(data);
  }
);
export const fetchMenu: any = createAsyncThunk(
  `${SPACE_NAME}/fetchMenu`,
  async userId => {
    const menus = await http("menu", "router", {
      params: {
        userId
      }
    });
    return formatMenu(menus);
  }
);



const slice = createSlice({
  name: SPACE_NAME,
  initialState,
  reducers: {
    setUserInfo(state, action) {
      state.userInfo = action.payload || {};
    }
  },
  extraReducers: {
    [fetchMenu.fulfilled](state, action) {
      const { menus, permission } = action.payload;
      state.menus = menus;
      state.permission = permission;
    },
    [fetchArea.fulfilled](state, action) {
      const { area } = action.payload;
      state.area = area;
    }
  }
});

export const { actions, reducer } = slice;

export default reducer;


function formatMenu(list: any) {
  const permission = {};

  function loop(data: any[]) {
    data.forEach(item => {
      const { resources = [], children = [] } = item;
      resources.forEach(v => {
        if (v.code) {
          permission[v.code] = true;
        }
      });
      loop(children);
    });
  }

  loop(list);

  localStorage.setItem("ROLE_LIST", JSON.stringify(list));
  localStorage.setItem("SOURCE_LIST", JSON.stringify(permission));

  return {
    menus: list,
    permission
  };
}

function formatArea(areaTree: any) {
  const data: any = []
  const IsSelfManageCity = (cities) => {
    const data = cities.filter((m) => m.children.length === 0)
    return data.length > 0
  }

  (areaTree || []).forEach(m => {
    const item = { ...m }
    if (IsSelfManageCity(m.children)) {
      item.children = [{
        id: m.id,
        name: m.name,
        children: m.children
      }]
    }
    data.push(item)
  })
  return {
    area: data
  }
}