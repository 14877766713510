export default {
  // 验证码
  captcha: {
    config: {
      url: "anno/captcha",
      method: "get",
      params: {
        key: ""
      }
    }
  },
  // 登录接口
  login: {
    config: {
      url: "noToken/login",
      method: "post",
      data: {
        grantType: "captcha",
        account: "",
        password: "",
        code: "",
        key: ""
      }
    }
  },
  // 退出登录
  logout: {
    config: {
      url: "noToken/logout",
      method: "post"
    }
  }
  // delete: {
  //   config: {
  //     url: '/monitoringSite',
  //     method: 'delete',
  //   },
  // },
};
