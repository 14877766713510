const target = 'meetOrderOperateLog'
export default {
  queryAll: {
    config: {
      url: `/${target}/query`,
      method: 'post',
    },
  }
};

