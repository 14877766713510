const target = 'dictionary'
export default {
  queryAll: {
    config: {
      url: `/${target}/query`,
      method: 'post',
    },
  },
  listKvByType: {
    config: {
      url: `/${target}/listKvByType`,
      method: 'post',
    },
  }
};

