import $ from 'jquery';
import React, { useEffect, useRef, useState } from 'react';

export const useSize = function (wait = 0) {
  const $window = $(window);
  const $body = $('body');
  const [size, setSize] = useState(function () {
    return getSize();
  });
  const _s: any = useRef(null);

  function getSize() {
    return {
      bWidth: $body.width(),
      bHeight: $body.height(),
      wWidth: $window.width(),
      wHeight: $window.height(),
    };
  }

  function resizeHandler() {
    const newSize = getSize();
    if (wait) {
      if (_s.current) {
        window.clearTimeout(_s.current);
      }

      _s.current = setTimeout(() => {
        setSize(newSize);
      }, wait);
    } else {
      setSize(newSize);
    }
  }

  useEffect(() => {
    $window.on('resize.HOOK', resizeHandler);
    return () => {
      $window.off('resize.HOOK', resizeHandler);
    };
  }, []);

  return size;
};

// 弹框控制
export const useModalControl = function (visible = false, data = {}) {
  const [modal, setModal] = useState({
    visible,
    data,
  });

  function show(data) {
    setModal({
      visible: true,
      data,
    });
  }

  function hide() {
    setModal({
      visible: false,
      data: {},
    });
  }

  return {
    modal,
    show,
    hide,
  };
};

export function useMounted() {
  const mountedRef = React.useRef(false);
  React.useEffect(() => {
    mountedRef.current = true;
  }, []);
  return mountedRef.current;
}

export function useTableScroll(
  container: React.RefObject<HTMLElement>,
  headerHeight: number,
  dep: any[],
  showHeader = true,
  pagination = true
) {
  const [scrollY, setScrollY] = React.useState(0);
  React.useEffect(() => {
    const getScroll = /* debounce( */ () => {
      if (!container.current) return;
      const { height } = container.current!.getBoundingClientRect();
      // header 57px 表头55px 分页64px
      console.log('height:', height)
      setScrollY(height - headerHeight - (showHeader ? 55 : 0) - (pagination ? 64 : 0));
    }; /* , 250) */
    getScroll();
    window.addEventListener('resize', getScroll);
    return () => {
      window.removeEventListener('resize', getScroll);
    };
  }, [headerHeight, pagination, ...dep]);

  return { scrollY };
}

export function useCache() {
  const [data, setData] = useState<any>({})
  const set = (key, value) => {
    setData({ ...data, [key]: value })
  }
  const get = (key) => {
    return data[key]
  }
  return {
    set,
    get
  }
}
