import React, {useEffect} from "react";
import Routers, { RouteList } from "@/router";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import zhCN from "antd/lib/locale-provider/zh_CN";
import { Router } from "react-router-dom";
import store from "./store";
import "antd/dist/antd.less";
import "@/style/index.less";
import history from "@/router/history";
import { useSize } from "@/hook";

const App: React.FC = props => {
  const size = useSize();

  function setHtmlFontSize() {
    let bHeight = size.bHeight;
    let fontsize = ((100 * bHeight) / 960).toFixed(2) + "px";
    (document as any).querySelector('html').style.fontSize = fontsize;
  }

  useEffect(() => {
    setHtmlFontSize();
  }, [size]);
  
  return (
    <Provider store={store}>
      <ConfigProvider locale={zhCN}>
        <Router history={history}>
          <Routers list={RouteList} />
        </Router>
      </ConfigProvider>
    </Provider>
  );
};

export default App;
