const sms = {
  list: {
    config: {
      url: '/tenantSms/page',
      method: 'post',
    },
  },
  add: {
    config: {
      url: '/tenantSms',
      method: 'post',
    },
  },
  update: {
    config: {
      url: '/tenantSms',
      method: 'put',
    },
  },
  delete: {
    config: {
      url: '/tenantSms',
      method: 'delete',
    },
  },
  export: {
    config: {
      url: '/tenantSms/export',
      method: 'post',
    },
  },
  onOff: {
    config: {
      url: '/tenantSms/onOffBatch',
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  },
};

export default sms;
