const target = 'browseLog'
export default {
  list: {
    config: {
      url: `/${target}/page`,
      method: 'post',
    },
  },
  query: {
    config: {
      url: `/${target}/id`,
      method: 'get',
    },
  },
  add: {
    config: {
      url: `/${target}`,
      method: 'post',
    },
  },
  update: {
    config: {
      url: `/${target}`,
      method: 'put',
    },
  },
  delete: {
    config: {
      url: `/${target}`,
      method: 'delete',
    },
  },
  export: {
    config: {
      url: `/${target}/export`,
      method: 'post',
      responseType: 'blob'
    },
  }
};

