const file = {
  list: {
    config: {
      url: '/file/page',
      method: 'post',
    },
  },
  add: {
    config: {
      url: '/file/anyone/upload',
      method: 'post',
      headers: {
        "Content-Type": "multipart/form-data",
      }
    },
  },
  update: {
    config: {
      url: '/file/anyone/upload',
      method: 'post',
      headers: {
        "Content-Type": "multipart/form-data",
      }
    },
  },
  delete: {
    config: {
      url: '/file',
      method: 'delete',
    },
  },
};

export default file;
