interface ConfigMap {
  [prop: string]: {
    [prop: string]: any;
  };
}

const apiModules = require.context('./', false, /\w+\.(ts)$/)
const excludesModules = ['./index.ts']
const configMap: ConfigMap = {}

apiModules.keys().forEach(key=>{
  if(!excludesModules.includes(key)){
    const name = key.replace(/(.*\/)*([^.]+).*/ig,"$2")
    const file = apiModules(key)
    configMap[name] = file.default || file
  }
})

export default configMap;
