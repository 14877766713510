const target = 'label'
export default {
  queryAll:{
    config: {
      url: `/${target}/query`,
      method: 'post',
    },
  }
};

