import request from '@/utils/request';
import $ from 'jquery';
import configMap from './config';

function getConfigItem(group: string, uelKey: string) {
  return configMap[group][uelKey];
}

function http(group: string, uelKey: string, userConfig?: object) {
  let configItem = getConfigItem(group, uelKey);
  let { config, handler } = configItem;

  config = $.extend(true, {}, config, userConfig || {});

  return request.request(config).then((rep): any => {
    return handler ? handler(rep) : rep;
  });
}

export default http;
