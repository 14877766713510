import React, { Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import history from "./history";
import { LoadingOutlined } from "@ant-design/icons";

const RouteList: any[] = [
  {
    path: "/management",
    component: React.lazy(() => import("@/views/management")),
    meta: { name: "" },
    redirect: true
  },
  {
    path: "/login",
    component: React.lazy(() => import("@/views/login")),
    exact: true,
    meta: { name: "登录页面" }
  },
];

const Routers: React.FC<{
  list: any[];
}> = props => {
  let { list = [] } = props;
  let redirectRouter = RouteList.filter(item => item.redirect === true)[0];

  if (
    redirectRouter &&
    redirectRouter.routes &&
    redirectRouter.routes.length > 0
  ) {
    redirectRouter = redirectRouter.routes[0];
  }

  return (
    <Suspense fallback={<div className="page-loading"><LoadingOutlined /></div>}>
      <Switch>
        {list.map((item, index) => {
          let { path, exact, component, routes } = item;
          let Com = component;
          return (
            <Route key={index} exact={exact} path={path} component={component}>
              <Com history={history} routes={routes} />
            </Route>
          );
        })}
        {redirectRouter ? <Redirect to={redirectRouter.path} /> : null}
      </Switch>
    </Suspense>
  );
};

export default Routers;

export { RouteList };
